
















import { Component } from "vue-property-decorator";
import { Guid } from 'guid-typescript';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import { SharedModule } from '@/feature/Shared/store';

import { CertificationStore, EditCertificationModel } from './store';
import PgaCertificationEdit from './Components/PgaCertificationEdit.vue';
import PgaCertificationEditAccessibility from './Accessibility/PgaCertificationEditAccessibility.vue';

@Component({
	components: {
		PgaCertificationEdit,
		PgaCertificationEditAccessibility,
	}
})

export default class CertificationPageEdit extends mixins(SharedMixin)
{
	get CertificationEdit() : EditCertificationModel
	{
		return CertificationStore.CertificationEdit;
	}

	get accessibility() : boolean
	{
		return SharedModule.Accessibility;
	}

	async Edit() : Promise<void>
	{
		try
		{
			await CertificationStore.EditCertification();
			this.$router.go(-1);
		}
		catch(error)
		{
			let errorMessage = error.get('id')?.pop() ?? "";
			if(errorMessage === "" && this.accessibility)
			{
				errorMessage = "There are followings errors: <br>";
				for(let key of CertificationStore.CertificationEdit.Errors.keys())
				{
					errorMessage += this.formattingErrorMessages(key);
				}
			}
			SharedModule.SET_ALERT_ERROR_MESSAGE(errorMessage);
			SharedModule.SET_ALERT_IS_VISIBLE(true);
		}
	}

	undo() : void 
	{
		this.$router.push({ path: '/certifications'});
    }
    
	async mounted() : Promise<void>
	{
        const id: string = this.$route.params.id;
		await CertificationStore.GetCertificationToEdit(Guid.parse(id));
	}
	
	beforeDestroy() : void
	{
		CertificationStore.CertificationEdit.Errors = new Map<string, Array<string>>();
	}

	private formattingErrorMessages ( key: string ): string
	{
		const errorMessages = CertificationStore.CertificationEdit.Errors.get( key );
		let allErrorMessages = "";
		errorMessages?.forEach( em => allErrorMessages += em + '<br>' );

		return allErrorMessages;
	}
}
