




























































import { Component, Model } from "vue-property-decorator";

import { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { CertificationStore } from "../store";
import { CreateCertificationCommand, ExpertiseTableModel, VendorModel, TypeCertification } from "@/services/iResourceService";

@Component({
	computed: {	},
})
export default class PgaCertificazioniCreate extends mixins(SharedMixin)
{  
	@Model("", { type: Object })  readonly Model!: CreateCertificationCommand;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private readonly typeCertification : Array<any> = 
	[
		{
			Name: 'Exam',
			Value: 1,
		},
		{
			Name: 'Certification',
			Value: 2,
		}
	];

	get code(): string
	{
		return this.Model?.Code ?? "";
	}
	set code(code: string)
	{
		CertificationStore.SET_CERTIFICATION_CREATE_CODE(code);
	}

	get description(): string
	{
		return this.Model?.Description ?? "";
	}
	set description(description: string)
	{
		CertificationStore.SET_CERTIFICATION_CREATE_DESCRIPTION(description);
	}

	get isActive(): boolean
	{
		return this.Model?.IsActive ?? true;
	}
	set isActive(value: boolean)
	{
		CertificationStore.SET_CERTIFICATION_CREATE_ISACTIVE(value);
	}

	get type(): TypeCertification
	{
		return this.Model?.Type ?? 1;
	}
	set type(value: TypeCertification)
	{
		CertificationStore.SET_CERTIFICATION_CREATE_TYPE(value);
	}

	get vendorId() : VendorModel | null
	{
		return null;
	}
	set vendorId(vendor: VendorModel | null)
	{
		vendor = vendor as VendorModel;
		CertificationStore.SET_CERTIFICATION_CREATE_VENDORID(vendor.Id);
	}

	get expertiseIds(): Array<ExpertiseTableModel>
	{
		return new Array<ExpertiseTableModel>();
	}
	set expertiseIds(expertise: Array<ExpertiseTableModel>)
	{
		CertificationStore.SET_CERTIFICATION_CREATE_EXPERTISEIDS(expertise.map(c => c.Id));
	}
	
	get Errors(): Map<string, Array<string>>
	{
		return CertificationStore.CertificationCreate.Errors;
	}

	get expertisesDropDown(): Array<ExpertiseTableModel>
	{
		return CertificationStore.ExpertisesDropDown;
	}

	get vendorsDropDown(): Array<VendorModel>
	{
		return CertificationStore.VendorsDropDown;
	}

	async mounted() : Promise<void>
	{
		await CertificationStore.GetVendorsDropDown();
	}
}
