

















































































import { Component, Model } from "vue-property-decorator";

import { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { CertificationStore } from '../store';
import { CreateCertificationCommand, ExpertiseTableModel, VendorModel, TypeCertification } from "@/services/iResourceService";

@Component( {
	computed: {},
} )
export default class PgaCertificationCreateAccessibility extends mixins( SharedMixin )
{
	@Model( "", { type: Object } ) readonly Model!: CreateCertificationCommand;

	get code (): string
	{
		return this.Model?.Code ?? "";
	}
	set code ( code: string )
	{
		CertificationStore.SET_CERTIFICATION_CREATE_CODE( code );
	}

	get description (): string
	{
		return this.Model?.Description ?? "";
	}
	set description ( description: string )
	{
		CertificationStore.SET_CERTIFICATION_CREATE_DESCRIPTION( description );
	}

	get isActive (): boolean
	{
		return this.Model?.IsActive ?? true;
	}
	set isActive ( value: boolean )
	{
		CertificationStore.SET_CERTIFICATION_CREATE_ISACTIVE( value );
	}

	get type (): TypeCertification
	{
		return this.Model?.Type ?? 1;
	}
	set type ( value: TypeCertification )
	{
		CertificationStore.SET_CERTIFICATION_CREATE_TYPE( value );
	}

	get vendorId (): VendorModel | null
	{
		const id = CertificationStore.CertificationCreate.Command?.VendorId;
		return this.vendorsDropDown.find( v => v.Id === id ) ?? null;
	}
	set vendorId ( vendor: VendorModel | null )
	{
		if(vendor)
			CertificationStore.SET_CERTIFICATION_CREATE_VENDORID( vendor.Id );
	}

	get expertiseIds (): Array<ExpertiseTableModel>
	{
		return new Array<ExpertiseTableModel>();
	}
	set expertiseIds ( expertise: Array<ExpertiseTableModel> )
	{
		CertificationStore.SET_CERTIFICATION_CREATE_EXPERTISEIDS( expertise.map( c => c.Id ) );
	}

	get Errors (): Map<string, Array<string>>
	{
		return CertificationStore.CertificationCreate.Errors;
	}

	get expertisesDropDown (): Array<ExpertiseTableModel>
	{
		return CertificationStore.ExpertisesDropDown;
	}

	get vendorsDropDown (): Array<VendorModel>
	{
		return CertificationStore.VendorsDropDown;
	}

	private showErrorMessages ( key: string ): string
	{
		const errorMessages = this.Errors.get( key );
		let allErrorMessages = "";
		errorMessages?.forEach( em => allErrorMessages += em + '<br/>' );

		return allErrorMessages;
	}

	private saveAccessibility() : void
	{
		this.$emit('save-accessibility');
	}

	async mounted (): Promise<void>
	{
		await CertificationStore.GetVendorsDropDown();
	}
}
