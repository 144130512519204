
















































































import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { CertificationStore } from "../store";
import { EditCertificationCommand, ExpertiseTableModel, TypeCertification, VendorModel } from "@/services/iResourceService";
import { Guid } from 'guid-typescript';

@Component( {} )
export default class PgaCertificationEditAccessibility extends mixins( SharedMixin )
{
	@Model( "", { type: Object } ) readonly Model!: EditCertificationCommand;

	get code (): string
	{
		return this.Model?.Code ?? "";
	}
	set code ( value: string )
	{
		CertificationStore.SET_CERTIFICATION_EDIT_CODE( value );
	}

	get description (): string
	{
		return this.Model?.Description ?? "";
	}
	set description ( value: string )
	{
		CertificationStore.SET_CERTIFICATION_EDIT_DESCRIPTION( value );
	}

	get isActive (): boolean
	{
		return this.Model?.IsActive ?? true;
	}
	set isActive ( value: boolean )
	{
		CertificationStore.SET_CERTIFICATION_EDIT_ISACTIVE( value );
	}

	get type (): TypeCertification
	{
		return this.Model?.Type ?? false;
	}
	set type ( value: TypeCertification )
	{
		CertificationStore.SET_CERTIFICATION_EDIT_TYPE( value );
	}

	get vendor (): VendorModel
	{
		const defaultVendor: VendorModel =
		{
			Id: Guid.createEmpty(),
			Code: '',
			BusinessName: '',
			Status: 0,
		};

		const id = CertificationStore.CertificationEdit.Command?.VendorId;
		return this.vendorsDropDown.find( v => v.Id === id ) ?? defaultVendor;
	}
	set vendor ( value: VendorModel )
	{
		CertificationStore.SET_CERTIFICATION_EDIT_VENDORID( value.Id );
	}

	get expertises (): Array<ExpertiseTableModel>
	{
		return CertificationStore.CertificationEdit.ViewModel?.Expertises ?? new Array<ExpertiseTableModel>();
	}
	set expertises ( value: Array<ExpertiseTableModel> )
	{
		CertificationStore.SET_CERTIFICATION_EDIT_EXPERTISEIDS( value.map( e => e.Id ) );
	}

	get Errors (): Map<string, Array<string>>
	{
		return CertificationStore.CertificationEdit.Errors;
	}

	get expertiseDropDown (): Array<ExpertiseTableModel>
	{
		return CertificationStore.ExpertisesDropDown;
	}
	get vendorsDropDown (): Array<VendorModel>
	{
		return CertificationStore.VendorsDropDown;
	}

	private showErrorMessages ( key: string ): string
	{
		const errorMessages = this.Errors.get( key );
		let allErrorMessages = "";
		errorMessages?.forEach( em => allErrorMessages += em + '<br/>' );

		return allErrorMessages;
	}

	private saveAccessibility() : void
	{
		this.$emit('save-accessibility');
	}

	async mounted (): Promise<void>
	{
		await CertificationStore.GetVendorsDropDown();
	}
}
