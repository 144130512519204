















































import { Component } from "vue-property-decorator";
import { Guid } from 'guid-typescript';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import { SharedModule } from "@/feature/Shared/store";

import { CertificationStore } from './store';
import { CertificationModel } from '@/services/iResourceService';

import PgaCertificationDetails from './Components/PgaCertificationDetails.vue';
import PgaCertificationDetailsAccessibility from './Accessibility/PgaCertificationDetailsAccessibility.vue';

@Component( {
	components:
	{
		PgaCertificationDetails,
		PgaCertificationDetailsAccessibility,
	}
} )
export default class CertificazioniPageDetails extends mixins( SharedMixin )
{
	private showDialog = false;
	private title = "";
	private message = "";

	get accessibility (): boolean
	{
		return SharedModule.Accessibility;
	}

	get CertificationDetails (): CertificationModel 
	{
		return CertificationStore.CertificationDetails;
	}

	async mounted (): Promise<void>
	{
		CertificationStore.DropCertificationDetails();
		const id: string = this.$route.params.id;
		await CertificationStore.GetCertificationDetails( Guid.parse( id ) );
	}

	ShowDialogDelete (): void
	{
		this.title = "Deleting Certification";
		this.message = `Are you sure you want to delete the Certification '${this.CertificationDetails.Description}'?`;
		this.showDialog = true;
	}

	HideDialog (): void
	{
		this.showDialog = false;
		this.title = "";
		this.message = "";
	}

	Edit (): void
	{
		if ( !this.CertificationDetails )
		{
			console.log( "view model is null" );
			return;
		}
		const id = this.CertificationDetails.Id.toString();
		this.$router.push( { name: 'certificationedit', params: { id } } );
	}

	SelectUsers (): void
	{
		if ( !this.CertificationDetails )
		{
			console.log( "view model is null" );
			return;
		}
		const id = this.CertificationDetails.Id.toString();
		this.$router.push( { name: 'certificationsselectusers', params: { id } } );
	}

	async Delete (): Promise<void>
	{
		try
		{
			if ( this.CertificationDetails )
				await CertificationStore.DeleteCertification( this.CertificationDetails.Id );
			this.$router.push( { path: '/certifications' } );
		}
		finally
		{
			this.showDialog = false;
			this.title = "";
			this.message = "";
		}
	}
}
