var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',[_vm._v("Select Users for "+_vm._s(_vm.description))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('h2',[_c('pga-text-field',{attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('button',{staticClass:"save",on:{"click":function($event){$event.stopPropagation();return _vm.saveAccessibility.apply(null, arguments)}}},[_vm._v("Save")])])],1),_c('table',{attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',[_c('th'),_c('th',[_vm._v("Full Name")]),_c('th',[_vm._v("Achievement Date")]),_c('th',[_vm._v("Expire Date")])])]),_c('tbody',_vm._l((_vm.listUsersFilterd),function(u){return _c('tr',{key:u.Id,attrs:{"role":"rowgroup"}},[_c('td',{attrs:{"role":"cell"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedUsers),expression:"selectedUsers"}],attrs:{"aria-label":'Select Users ' + u.FullName,"type":"checkbox","name":"selectuser","id":"selectuser"},domProps:{"value":{
							Id: u.Id,
							FullName: '',
							AttainmentDate: u.AttainmentDate,
							ExpireDate: u.ExpireDate,
						},"checked":Array.isArray(_vm.selectedUsers)?_vm._i(_vm.selectedUsers,{
							Id: u.Id,
							FullName: '',
							AttainmentDate: u.AttainmentDate,
							ExpireDate: u.ExpireDate,
						})>-1:(_vm.selectedUsers)},on:{"change":function($event){var $$a=_vm.selectedUsers,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v={
							Id: u.Id,
							FullName: '',
							AttainmentDate: u.AttainmentDate,
							ExpireDate: u.ExpireDate,
						},$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedUsers=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedUsers=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedUsers=$$c}}}})]),_c('td',[_vm._v(_vm._s(u.FullName))]),_c('td',[(_vm.isSelected(u.Id))?_c('input',{staticClass:"hide-calendar",attrs:{"aria-label":'Achievement Date of ' + u.FullName,"type":"date","id":u.Id},domProps:{"value":_vm.formatDate(_vm.getAttainmentDate(u.Id))},on:{"input":function($event){u.AttainmentDate = _vm.setAttainmentDate(u.Id, $event.target.valueAsDate)}}}):_vm._e(),(_vm.showError(u.Id, 'AttainmentDate'))?_c('span',{staticClass:"red--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm.showErrorMessage("AttainmentDate"))+" ")]):_vm._e()]),_c('td',[(_vm.isSelected(u.Id))?_c('input',{attrs:{"aria-label":"Check to see input to add Expire Date","type":"checkbox","id":"expire","name":"expire"},domProps:{"checked":_vm.getExpireDate(u.Id) !== null},on:{"input":function($event){u.ExpireDate = _vm.setExpireDate(u.Id, $event.target.checked ? new Date() : null)}}}):_vm._e(),(_vm.isSelected(u.Id) && u.ExpireDate !== null)?_c('input',{staticClass:"hide-calendar",attrs:{"aria-label":'Expire Date of ' + u.FullName,"type":"date","id":u.Id},domProps:{"value":_vm.formatDate(_vm.getExpireDate(u.Id))},on:{"input":function($event){_vm.setExpireDate(u.Id, $event.target.valueAsDate); u.ExpireDate = $event.target.valueAsDate;}}}):_vm._e(),(_vm.showError(u.Id, 'ExpireDate'))?_c('span',{staticClass:"red--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm.showErrorMessage("ExpireDate"))+" ")]):_vm._e()])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }