import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '@/infrastructure/store';

import iIdentityService, { UserModel } from '@/services/iIdentityService';
import IdentityService from '@/services/IdentityService';

import pgaDiContainer from '@/App.container';

export interface LookUpState
{
    Users: Array<UserModel> 
}

@Module({ namespaced: true, dynamic: true, store, name: 'LookUpModule', })
class LookUpStore extends VuexModule implements LookUpState
{
    private identityService: iIdentityService = pgaDiContainer.get<iIdentityService>(IdentityService);

    Users: Array<UserModel> = new Array<UserModel>();

    //List Users
    @Action({ rawError: true })
    public async GetListUsers()
    {
        const users: Array<UserModel> = (await this.identityService.ListUsers()).Users;
        this.SET_USERS(users);
    }
    @Mutation
    public SET_USERS(users: Array<UserModel>)
    {
        this.Users = users;
    }

}

export default getModule(LookUpStore);