































































































import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { CertificationModel, ExpertiseTableModel, TypeCertification, UserResource } from "@/services/iResourceService";

@Component( {} )
export default class PgaCertificationDetailsAccessibility extends mixins( SharedMixin )
{
	@Model( "", { type: Object } ) readonly Model!: CertificationModel;

	private accessibility = false;
	private headers: Array<unknown> =
		[
			{
				text: "Name",
				align: "start",
				sortable: true,
				value: "FullName",
			},
			{
				text: "Achievement Date",
				align: "start",
				sortable: false,
				value: "AttainmentDate",
				width: "25%",
			},
			{
				text: "Expire Date",
				align: "start",
				sortable: false,
				value: "ExpireDate",
				width: "25%",
			},
		];

	get code (): string
	{
		return this.Model?.Code ?? "";
	}

	get description (): string
	{
		return this.Model?.Description ?? "";
	}

	get isActive (): boolean
	{
		return this.Model?.IsActive ?? true;
	}

	get type (): TypeCertification
	{
		return this.Model?.Type ?? 0;
	}

	get vendor (): string
	{
		return this.Model?.Vendor.BusinessName ?? "";
	}

	get expertises (): Array<ExpertiseTableModel>
	{
		return this.Model?.Expertises ?? new Array<ExpertiseTableModel>();
	}

	get users (): Array<UserResource>
	{
		return this.Model?.Users ?? new Array<UserResource>();
	}

	private formatDate ( date: Date ): string
	{
		if ( date === null )
			return "";
		date = new Date( date );
		return date.toLocaleDateString();
	}

	private editAccessibility() : void
	{
		this.$emit('edit-accessibility');
	}

	private selectUsersAccessibility() : void
	{
		this.$emit('select-users-accessibility');
	}

	private tableCertifications() : void
	{
		this.$router.go(-1);
	}
}
