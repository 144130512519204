

















































import { Component, Prop, Model } from "vue-property-decorator";

import ValidationMixin from "@/mixins/ValidationMixin";
import { mixins } from "vue-class-component";
import { Guid } from "guid-typescript";
import { SharedModule } from "@/feature/Shared/store";

@Component( {} )
export default class PgaCertificationDatePicker extends mixins( ValidationMixin ) {
	private showMenu = false;
	private dateTemp: Date | null = null;
	private isExpireDate = false;

	@Prop( { type: String, default: Guid.createEmpty() } ) readonly Id!: Guid;
	@Prop( { type: Object, default: function () { return {} } } ) readonly object?: unknown;

	@Prop( { type: String, default: "" } ) readonly label!: string;
	@Prop( { type: String, default: "" } ) readonly labelCheckbox!: string;
	@Prop( { type: Boolean, default: false } ) readonly readonly!: boolean;
	@Prop( { type: Boolean, default: false } ) readonly nullable!: boolean;

	@Prop( { type: String, default: "" } ) readonly keyError!: string;
	@Prop( { type: String, default: "" } ) readonly rowKeyError!: string;
	@Prop( {
		default: function ()
		{
			return new Map<string, Array<string>>();
		},
	} )
	readonly Errors!: Map<string, Array<string>>;

	@Prop( { type: Date, default: function () { return new Date() } } ) readonly value!: Date | null;
	@Model( "input", { type: Date } ) Model!: Date | null;
	updateValue ( value: Date | null ): void
	{
		this.showMenu = false;
		if ( value != null ) this.dateTemp = new Date( value );
		this.$emit( "change-date", this.Id, value );
	}

	get accessibility (): boolean
	{
		return SharedModule.Accessibility;
	}

	get dateTempString (): string
	{
		return this.dateTemp?.toLocaleDateString() ?? "No date present";
	}

	get isExpire (): boolean
	{
		return this.isExpireDate;
	}
	set isExpire ( value: boolean )
	{
		if ( value && this.dateTemp === null )
		{
			this.dateTemp = new Date( Date.now() );
		}
		else if ( !value )
		{
			this.dateTemp = null;
		}

		this.updateValue( this.dateTemp );
		this.isExpireDate = value;
	}

	get dateFormat (): string
	{
		let dateString = "";
		if ( this.dateTemp != null )
			dateString = ( new Date( this.dateTemp ) ).toISOString().substr( 0, 10 );
		return dateString;
	}

	showError (): boolean
	{
		if ( this.Errors.size )
		{
			const hasErrors = this.hasErrors( this.keyError );
			const rowHasErrors =
				this.errors( this.rowKeyError ).includes( this.Id.toString() ) ?? false;
			return hasErrors && rowHasErrors;
		}
		return false;
	}

	showErrorMessage (): string
	{
		return this.errors( this.keyError )[0] ?? "";
	}

	mounted (): void
	{
		if ( this.Model != null || this.value != null )
		{
			const model = this.Model?  this.Model : this.value;
			const dateModel = new Date( model ?? new Date() );
			dateModel.setHours( 12 );
			this.dateTemp = dateModel;
			this.isExpire = true;
		}
		else
		{
			this.dateTemp = this.Model;
			this.isExpire = false;
		}
	}
}
