

























































































import { Model } from 'vue-property-decorator';

import { Guid } from 'guid-typescript';
import Component, { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { EditCertificationCommand, UserAttainmentDateResource, UserIdDate } from '@/services/iResourceService';
import LookUpStore from '@/feature/Shared/LookUp/store';
import { CertificationStore } from '../store';

import { SharedModule } from '@/feature/Shared/store';

@Component({})
export default class PgaCertificationsSelectUsersAccessibility extends mixins( SharedMixin )
{
	@Model( "", { type: Object } ) readonly Model!: EditCertificationCommand;

	get description (): string
	{
		return CertificationStore.CertificationDetails?.Description ?? "";
	}

	private Search = "";
	get search (): string
	{
		return this.Search;
	}
	set search ( value: string )
	{
		this.Search = value;
	}

	get listUsersFilterd (): Array<UserAttainmentDateResource>
	{
		if ( this.Search == "" || this.Search == null || this.Search == undefined )
		{
			return this.ListUsers;
		}
		else
		{
			return this.ListUsers.filter( i => objectMatchesText( this.Search, i ) );
		}
	}

	private ListUsers = new Array<UserAttainmentDateResource>();
	get listUsers (): Array<UserAttainmentDateResource>
	{
		return this.ListUsers;
	}
	set listUsers ( value: Array<UserAttainmentDateResource> )
	{
		this.ListUsers = value;
	}

	get selectedUsers (): Array<UserAttainmentDateResource>
	{
		const selectedUserIdDates: Array<UserAttainmentDateResource> = new Array<UserAttainmentDateResource>();
		CertificationStore.CertificationSelectUsers.Command?.Users
			.forEach( ud =>
			{
				selectedUserIdDates.push( { Id: ud.UserId, FullName: '', AttainmentDate: ud.AttainmentDate, ExpireDate: ud.ExpireDate, } );
			} )

		return selectedUserIdDates;
	}
	set selectedUsers ( value: Array<UserAttainmentDateResource> )
	{
		const userIdDates: Array<UserIdDate> = new Array<UserIdDate>();
		value.forEach( u =>
		{
			if ( !userIdDates.some( uid => uid.UserId == u.Id ) )
				userIdDates.push( { UserId: u.Id, AttainmentDate: u.AttainmentDate, ExpireDate: u.ExpireDate, } );

		} );

		CertificationStore.SET_CERTIFICATION_SELECTUSERS_USERS( userIdDates );
	}

	private setAttainmentDate ( userId: Guid, attainmentDate: Date ): Date
	{
		attainmentDate = attainmentDate ?? new Date();
		const userIdDate: UserIdDate =
		{
			UserId: userId,
			AttainmentDate: attainmentDate,
			ExpireDate: null,
		};
		CertificationStore.SET_CERTIFICATION_SELECTUSERS_ATTAINMENT( userIdDate );

		return attainmentDate;
	}

	private setExpireDate ( userId: Guid, expireDate: Date ): Date
	{
		const userIdDate: UserIdDate =
		{
			UserId: userId,
			AttainmentDate: new Date(),
			ExpireDate: expireDate,
		};
		CertificationStore.SET_CERTIFICATION_SELECTUSERS_EXPIRE( userIdDate );

		return expireDate;
	}

	private getAttainmentDate ( userId: Guid ): Date
	{
		const userById = CertificationStore.CertificationSelectUsers.Command?.Users
			.find(uc => uc.UserId == userId)
		return userById?.AttainmentDate ?? new Date();
	}

	private getExpireDate ( userId: Guid ): Date | null
	{
		const userById = CertificationStore.CertificationSelectUsers.Command?.Users
			.find(uc => uc.UserId == userId)
		return userById?.ExpireDate ?? null;
	}

	private isSelected ( userId: Guid ): boolean
	{
		const isSelected = this.selectedUsers.some( su => su.Id === userId );
		return isSelected;
	}

	private formatDate ( date: Date ): string
	{
		if ( date === null )
			return "0001-01-01";

		if ( typeof date === "string" )
			return ( date as string ).split( "T" )[0];

		return date.toISOString().split( "T" )[0];
	}

	private initListUser (): Array<UserAttainmentDateResource>
	{
		const listUserDates: Array<UserAttainmentDateResource> = new Array<UserAttainmentDateResource>( ...CertificationStore.CertificationDetails?.Users );
		const listUserIdDatess: Array<Guid> = listUserDates.map( u => u.Id );
		LookUpStore.Users.forEach( u =>
		{
			if ( !listUserIdDatess.includes( u.Id ) )
			{
				const attainmentDate = new Date( Date.now() );
				listUserDates.push( { Id: u.Id, FullName: u.FullName, AttainmentDate: attainmentDate, ExpireDate: null } )
			}
		} );

		return listUserDates;
	}

	get Errors (): Map<string, Array<string>>
	{
		return CertificationStore.CertificationSelectUsers.Errors;
	}
	private showError ( userId: Guid, keyError: string ): boolean
	{
		if ( this.Errors.size )
		{
			const hasErrors = this.Errors.has( keyError );
			const rowHasErrors =
				this.Errors.get( keyError + 'UserIds' )?.includes( userId.toString() ) ?? false;
			return rowHasErrors && hasErrors;
		}
		return false;
	}

	private showErrorMessage ( keyError: string ): string
	{
		const arrayErrors = this.Errors.get( keyError ) ?? new Array<string>();
		return arrayErrors[0];
	}

	private saveAccessibility() : void 
	{ 
		this.$emit('save-accessibility')
	}

	async created (): Promise<void>
	{
		SharedModule.SET_ISLOADING( true );

		await LookUpStore.GetListUsers();

		const id: string = this.$route.params.id;
		await CertificationStore.GetCertificationDetails( Guid.parse( id ) );
		CertificationStore.InitCertificationSelectUsers();

		this.ListUsers = this.initListUser();

		SharedModule.SET_ISLOADING( false );
	}
}

function objectMatchesText ( text: string, obj )
{
	if ( obj === null)
		return false;

	if ( typeof obj === "string" )
	{
		return obj.toLowerCase().includes( text.toLowerCase() );
	}

	return Object.values( obj ).some( val => objectMatchesText( text, val ) );
}
