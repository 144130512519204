






















import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import { SharedModule } from "@/feature/Shared/store";

import { CertificationStore, CreateCertificationModel } from "./store";
import { CreateCertificationResponse } from '@/services/iResourceService';

import PgaCertificationCreate from "./Components/PgaCertificationCreate.vue";
import PgaCertificationCreateAccessibility from "./Accessibility/PgaCertificationCreateAccessibility.vue";

@Component( {
	components: {
		PgaCertificationCreate,
		PgaCertificationCreateAccessibility,
	},
} )
export default class CertificationPageCreate extends mixins( SharedMixin )
{
	get CertificationCreate (): CreateCertificationModel
	{
		return CertificationStore.CertificationCreate;
	}

	get accessibility (): boolean
	{
		return SharedModule.Accessibility;
	}

	async Create (): Promise<void>
	{
		try
		{
			const response: CreateCertificationResponse = await CertificationStore.CreateCertification();
			const id: string = response.Id.toString();
			this.$router.replace( { name: 'certificationdetails', params: { id } } );

		}
		catch ( error )
		{
			if(this.accessibility)
			{
				let allErrorMessages = "";
				for(let key of CertificationStore.CertificationCreate.Errors.keys())
				{
					allErrorMessages += this.formattingErrorMessages(key);
				}
				
				SharedModule.SET_ALERT_ERROR_MESSAGE("There are followings errors: <br>" +  allErrorMessages);
				SharedModule.SET_ALERT_IS_VISIBLE(true);
			}
		}
	}

	undo (): void
	{
		this.$router.go( -1 );
	}

	mounted (): void
	{
		CertificationStore.InitCertificationCreate();
	}

	beforeDestroy (): void
	{
		CertificationStore.DropExpertiseCreate();
		CertificationStore.CertificationCreate.Errors = new Map<string, Array<string>>();
	}

	private formattingErrorMessages ( key: string ): string
	{
		const errorMessages = CertificationStore.CertificationCreate.Errors.get( key );
		let allErrorMessages = "";
		errorMessages?.forEach( em => allErrorMessages += em + '<br>' );

		return allErrorMessages;
	}
}
