




































































































import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { CertificationModel, ExpertiseTableModel, TypeCertification, UserResource } from "@/services/iResourceService";

import PgaTypeCertification from "./PgaTypeCertification.vue";
import PgaCertificationDatePicker from "./PgaCertificationDatePicker.vue";

@Component( {
	components:
	{
		PgaTypeCertification,
		PgaCertificationDatePicker,
	},
} )
export default class PgaCertificazioniDetails extends mixins( SharedMixin )
{
	@Model( "", { type: Object } ) readonly Model!: CertificationModel;

	private accessibility = false;
	headers: Array<unknown> =
		[
			{
				text: "Name",
				align: "start",
				sortable: true,
				value: "FullName",
				width: "50%",
			},
			{
				text: "Achievement Date",
				align: "start",
				sortable: false,
				value: "AttainmentDate",
				width: "25%",
			},
			{
				text: "Expire Date",
				align: "start",
				sortable: false,
				value: "ExpireDate",
				width: "25%",
			},
		];

	get code (): string
	{
		return this.Model?.Code ?? "";
	}

	get description (): string
	{
		return this.Model?.Description ?? "";
	}

	get isActive (): boolean
	{
		return this.Model?.IsActive ?? true;
	}

	get type (): TypeCertification
	{
		return this.Model?.Type ?? 0;
	}

	get vendor (): string
	{
		return this.Model?.Vendor.BusinessName ?? "";
	}

	get expertises (): Array<ExpertiseTableModel>
	{
		return this.Model?.Expertises ?? new Array<ExpertiseTableModel>();
	}

	get users (): Array<UserResource>
	{
		return this.Model?.Users ?? new Array<UserResource>();
	}

	get modifiedDate (): string
	{
		const date: Date = new Date( this.Model?.ModifiedDate ) ?? new Date();
		return date.toLocaleString();
	}
	get modifiedBy (): string
	{
		return this.Model?.ModifiedBy.FullName ?? "";
	}
}
