























	import { Component } from "vue-property-decorator";
	import { Guid } from 'guid-typescript';

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";
	import { SharedModule } from "@/feature/Shared/store";

	import { CertificationStore, SelectUsersCertificationModel } from './store';

	import PgaCertificationsSelectUsers from './Components/PgaCertificationsSelectUsers.vue';
	import PgaCertificationsSelectUsersAccessibility from './Accessibility/PgaCertificationsSelectUsersAccessibility.vue';
import LookUpStore from '@/feature/Shared/LookUp/store';

	@Component( {
		components:
		{
			PgaCertificationsSelectUsers,
			PgaCertificationsSelectUsersAccessibility,
		}
	} )
	export default class CertificationsPageSelectUsers extends mixins( SharedMixin )
	{
		get CertificationsSelectUsers (): SelectUsersCertificationModel
		{
			return CertificationStore.CertificationSelectUsers;
		}

		get accessibility (): boolean
		{
			return SharedModule.Accessibility;
		}

		async SelectUsers (): Promise<void>
		{
			try
			{
				let id: string = this.$route.params.id;
				await CertificationStore.SelectUsersCertification( Guid.parse( id ) );
				this.$router.go( -1 );
			}
			catch ( error ) 
			{
				if(this.accessibility)
				{
					const mapUsers = new Map<Guid, string>();
					let userIdsString = error.get('ExpireDateUserIds') ?? new Array<string>();
					userIdsString = userIdsString.concat(error.get('AttainmentDateUserIds') ?? new Array<string>());
					userIdsString
						.filter((v, i, a) => a.indexOf(v) === i)
						.forEach(uid => 
						{
							const fullName = LookUpStore.Users.find(user => user.Id.toString() == uid)?.FullName ?? "";
							if(fullName !== "")
								mapUsers.set(Guid.parse(uid), fullName);
						});

					let allErrorMessages = "There are followings errors: <br>";
					for(let user of mapUsers)
					{
						const hasErrAttainment = error.get('AttainmentDateUserIds')?.includes(user[0].toString()) ?? false;
						const hasErrExpire = error.get('ExpireDateUserIds')?.includes(user[0].toString()) ?? false;
						const errAttainment = hasErrAttainment? "The Date must be earlier than or equal today" : "";
						const errExpire = hasErrExpire? "The Date must be after than to Achievement date" : "";

						if(hasErrAttainment || hasErrExpire)
							allErrorMessages += user[1] + ": " + errAttainment + ", " + errExpire + "<br>";
					}
					
					SharedModule.SET_ALERT_ERROR_MESSAGE(allErrorMessages);
					SharedModule.SET_ALERT_IS_VISIBLE(true);
				}
			}
		}

		undo (): void 
		{
			this.$router.go( -1 );
		}

		beforeDestroy (): void
		{
			CertificationStore.CertificationEdit.Errors = new Map<string, Array<string>>();
			CertificationStore.CertificationSelectUsers.Errors = new Map<string, Array<string>>();
		}

		private formattingErrorMessages ( key: string ): string
		{
			const errorMessages = CertificationStore.CertificationSelectUsers.Errors.get( key );
			let allErrorMessages = "";
			errorMessages?.forEach( em => allErrorMessages += em );

			return allErrorMessages;
		}
	}
