




























































import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { CertificationStore } from "../store";
import { EditCertificationCommand, ExpertiseTableModel, TypeCertification, VendorModel } from "@/services/iResourceService";
import { Guid } from 'guid-typescript';

@Component({ })
export default class PgaCertificationEdit extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: EditCertificationCommand;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private readonly typeCertification : Array<any> = 
	[
		{
			Name: 'Exam',
			Value: 1,
		},
		{
			Name: 'Certification',
			Value: 2,
		}
	];

	get code(): string
	{
		return this.Model?.Code ?? "";
	}
	set code(value: string)
	{
		CertificationStore.SET_CERTIFICATION_EDIT_CODE(value);
	}

	get description(): string
	{
		return this.Model?.Description ?? "";
	}
	set description(value: string)
	{
		CertificationStore.SET_CERTIFICATION_EDIT_DESCRIPTION(value);
	}

	get isActive(): boolean
	{
		return this.Model?.IsActive ?? true;
	}
	set isActive(value: boolean)
	{
		CertificationStore.SET_CERTIFICATION_EDIT_ISACTIVE(value);
	}

	get type(): TypeCertification
	{
		return this.Model?.Type ?? false;
	}
	set type(value: TypeCertification)
	{
		CertificationStore.SET_CERTIFICATION_EDIT_TYPE(value);
	}

	get vendor(): VendorModel
	{
		const defaultVendor: VendorModel =
		{
			Id: Guid.createEmpty(),
			Code: '',
			BusinessName: '',
			Status: 0,
		}
		return CertificationStore.CertificationEdit.ViewModel?.Vendor ?? defaultVendor;
	}
	set vendor(value: VendorModel)
	{
		CertificationStore.SET_CERTIFICATION_EDIT_VENDORID(value.Id);
	}

	get expertises(): Array<ExpertiseTableModel>
	{
		return CertificationStore.CertificationEdit.ViewModel?.Expertises ?? new Array<ExpertiseTableModel>();
	}
	set expertises(value: Array<ExpertiseTableModel>)
	{
		CertificationStore.SET_CERTIFICATION_EDIT_EXPERTISEIDS(value.map(e => e.Id));
	}

	get Errors(): Map<string, Array<string>>
	{
		return CertificationStore.CertificationEdit.Errors;
	}

	get expertiseDropDown(): Array<ExpertiseTableModel>
	{
		return CertificationStore.ExpertisesDropDown;
	}
	get vendorsDropDown(): Array<VendorModel>
	{
		return CertificationStore.VendorsDropDown;
	}

	async mounted(): Promise<void>
	{
		await CertificationStore.GetVendorsDropDown();
	}
}
