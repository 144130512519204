








































import { Model } from 'vue-property-decorator';

import { Guid } from 'guid-typescript';
import Component, { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { EditCertificationCommand, UserAttainmentDateResource, UserIdDate } from '@/services/iResourceService';
import LookUpStore from '@/feature/Shared/LookUp/store';
import { CertificationStore } from '../store';

import PgaCertificationDatePicker from './PgaCertificationDatePicker.vue';

@Component( {
	components: {
		PgaCertificationDatePicker,
	}
} )
export default class PgaCertificationsSelectUsers extends mixins( SharedMixin )
{
	@Model( "", { type: Object } ) readonly Model!: EditCertificationCommand;

	private isLoading = true;
	private _isExpire = false;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	headers: Array<any> = [
		{
			text: "Full Name",
			align: "start",
			sortable: true,
			value: "FullName",
		},
		{
			text: "Achievement Date",
			align: "start",
			sortable: false,
			value: "AttainmentDate",
		},
		{
			text: "Expire Date",
			align: "start",
			sortable: false,
			value: "ExpireDate",
		},
	];

	get description (): string
	{
		return CertificationStore.CertificationDetails?.Description ?? "";
	}

	get listUsers (): Array<UserAttainmentDateResource>
	{
		const listUserDates: Array<UserAttainmentDateResource> = new Array<UserAttainmentDateResource>( ...CertificationStore.CertificationDetails?.Users );
		const listUserIdDatess: Array<Guid> = listUserDates.map( u => u.Id );
		LookUpStore.Users.forEach( u =>
		{
			if ( !listUserIdDatess.includes( u.Id ) )
			{
				const attainmentDate = new Date( Date.now() );
				listUserDates.push( { Id: u.Id, FullName: u.FullName, AttainmentDate: attainmentDate, ExpireDate: null } )
			}
		} );

		return listUserDates;
	}

	get selectedUsers (): Array<UserAttainmentDateResource>
	{
		const selectedUserIdDates: Array<UserAttainmentDateResource> = new Array<UserAttainmentDateResource>();
		CertificationStore.CertificationSelectUsers.Command?.Users
			.forEach( ud =>
			{
				selectedUserIdDates.push( { Id: ud.UserId, FullName: '', AttainmentDate: ud.AttainmentDate, ExpireDate: ud.ExpireDate, } );
			} )

		return selectedUserIdDates ?? new Array<UserAttainmentDateResource>();
	}
	set selectedUsers ( value: Array<UserAttainmentDateResource> )
	{
		const userIdDates: Array<UserIdDate> = new Array<UserIdDate>();
		value.forEach( u =>
			userIdDates.push( { UserId: u.Id, AttainmentDate: u.AttainmentDate, ExpireDate: u.ExpireDate, } ) );

		CertificationStore.SET_CERTIFICATION_SELECTUSERS_USERS( userIdDates );
	}

	showDate ( userId: Guid ): boolean
	{
		return this.selectedUsers.map( u => u.Id ).includes( userId );
	}

	setAttainmentDate ( userId: Guid, attainmentDate: Date ): void
	{
		const userIdDate: UserIdDate =
		{
			UserId: userId,
			AttainmentDate: attainmentDate,
			ExpireDate: null,
		};
		CertificationStore.SET_CERTIFICATION_SELECTUSERS_ATTAINMENT( userIdDate );
	}

	setExpireDate ( userId: Guid, expireDate: Date ): void
	{
		const userIdDate: UserIdDate =
		{
			UserId: userId,
			AttainmentDate: new Date(),
			ExpireDate: expireDate,
		};
		CertificationStore.SET_CERTIFICATION_SELECTUSERS_EXPIRE( userIdDate );
	}

	private getAttainmentDate ( userId: Guid ): Date
	{
		const userById = CertificationStore.CertificationSelectUsers.Command?.Users
			.find(uc => uc.UserId == userId)
		return userById?.AttainmentDate ?? new Date();
	}

	private getExpireDate ( userId: Guid ): Date | null
	{
		const userById = CertificationStore.CertificationSelectUsers.Command?.Users
			.find(uc => uc.UserId == userId)
		return userById?.ExpireDate ?? null;
	}

	get Errors (): Map<string, Array<string>>
	{
		return CertificationStore.CertificationSelectUsers.Errors;
	}

	async created (): Promise<void>
	{
		this.isLoading = true;

		await LookUpStore.GetListUsers();

		const id: string = this.$route.params.id;
		await CertificationStore.GetCertificationDetails( Guid.parse( id ) );
		CertificationStore.InitCertificationSelectUsers();

		this.isLoading = false;
	}
}
